.background{
    /* background-image: url("C:\Users\agust\OneDrive\Escritorio\pi\client\src\components\img\abstract-smooth-empty-grey-studio-well-use-as-background-business-report-digital-website-template-backdrop.jpg"); */
   height: 100% ;
  width: 100% ;
   /* height: auto; */
   background-size:cover;
   background-position:right;
   background-repeat: no-repeat;
   position:absolute;
   top: 10;
   left: 0;
   /* display: flex;  */
    /* align-items: flex-start;  */
   flex-direction: column;
   justify-content: space-between;
}

.formulario{
   width:300px;
   margin: 0 auto;
}
select{
    /* width: 100%; */
    padding: 16px 20px;
    border: none;
    border-radius: 4px;
    background-color: #f1f1f1;
  }
  input {
     width: 15%;
    padding: 9px 20px;
    margin: 8px 0;
    box-sizing: border-box;
  }
  .button {
    margin: 1rem;
    border: none;
    /* padding: 0.7em 2em; */
    padding: 0.7em 2em;
    font-size: 18px;
    font-family: inherit;
    background-blend-mode: multiply;
    color: rgb(235, 234, 234);
    border-radius: 9px;
    background: linear-gradient(225deg, #181818, #2e2e2e);
    /* box-shadow: -5px 5px 10px #191919,
                5px -5px 10px #292929; */
   }
   
  .button:hover {
    background: #212121;
    transition: background 1s ease;
    box-shadow: -5px 5px 10px #191919,
                5px -5px 10px #292929;
   }
   
   .button:active {
    transition: box-shadow .1s ease;
    border-radius: 9px;
    background: #212121;
    box-shadow: inset -5px 5px 10px #191919,
                 inset 5px -5px 10px #292929;
   }
   
   .span {
    opacity: 0.5;
    transition: opacity 0.2s ease;
   }
   
   .button:hover span {
    opacity: 0.7;
   }

 .temp{
    padding: 1rem;
} 
.inputTemp{
    width: 40px;
}