@import url(https://fonts.googleapis.com/css?family=Raleway);
body {
    margin: 0 px;
  }
  nav {
    /* margin-top: 40px; */
    /* height: 20%; */
    /* height: 5rem; */
    padding: 24px;
    text-align: end;
    font-family: Roboto;
    /* box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.5); */
    background-color: #bebebe;
    display: block;
  }
 .nav {
    background: #bebebe;
    height: 72px; 
   display: flex;
   justify-content: space-between;
   
    
  }
  

  .link:hover {
    border-top: 4px solid #ffffff;
    border-bottom: 4px solid #ffffff;
    padding: 6px 0; 
    /* margin: 0%; */
  }
  .link{
    transition: 0.3s ease;
  background: #181818;
  color: #ffffff;
  font-size: 20px;
  text-decoration: none;
  border-top: 4px solid #9256ED;
  border-bottom: 4px solid #9256ED;
  padding: 20px 0;
  margin: 0 20px;
  }


.cardContent{
    display: flex;
    /* margin: auto; */
    padding: 2rem;
    font: times;
    width: auto;
    height: auto;
    /* background-image: url(../Landing/landing.jpg); */
    flex-direction: row;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
    align-items: center;
    /* background-color: rgba(253, 253, 253, 0.758); */

} 

.orden{
    margin: 1rem;
    border: none;
    padding: 0.7em 2em;
    font-size: 13px;
    font-family: inherit;
    background-blend-mode: multiply;
    color: rgb(235, 234, 234);
    border-radius: 9px;
    background: linear-gradient(225deg, #181818, #2e2e2e);
    /* box-shadow: -5px 5px 10px #191919,
                5px -5px 10px #292929; */
                /* justify-content: space-evenly; */
   }
   
  .orden:hover {
    background: #212121;
    transition: background 1s ease;
    box-shadow: -5px 5px 10px #191919,
                5px -5px 10px #292929;
   }
   
   .orden:active {
    transition: box-shadow .1s ease;
    border-radius: 9px;
    background: #212121;
    box-shadow: inset -5px 5px 10px #191919,
                 inset 5px -5px 10px #292929;
   }
   
   .span {
    opacity: 0.5;
    transition: opacity 0.2s ease;
   }
   
   .orden:hover span {
    opacity: 0.7;
   }

   .logo {
    display: flex;
    width: 200px;
    margin: 0 auto;
  }
.background{
    /* background-image: url("C:\Users\agust\OneDrive\Escritorio\pi\client\src\components\img\abstract-smooth-empty-grey-studio-well-use-as-background-business-report-digital-website-template-backdrop.jpg");  */
   min-height:100%;
   min-width: 100%;
    height: auto;
    background-size:cover;
    background-position:center;
    background-repeat: no-repeat;
     position:absolute;
    /* top: 10;
    left: 0;
    display: flex;  
    align-items: flex-start;   
    flex-direction:row;
   justify-content: space-between; */
    background-color: #bebebe 
}



    .button {
        border: none;
        padding: 0.7em 2em;
        font-size: 15px;
        font-family: inherit;
        background-blend-mode: multiply;
        color: rgb(235, 234, 234);
        border-radius: 9px;
        background: linear-gradient(225deg, #181818, #2e2e2e);
        /* box-shadow: -5px 5px 10px #191919,
                    5px -5px 10px #292929; */
       }
       
      .button:hover {
        background: #212121;
        transition: background 1s ease;
        box-shadow: -5px 5px 10px #191919,
                    5px -5px 10px #292929;
       }
       
       .button:active {
        transition: box-shadow .1s ease;
        border-radius: 9px;
        background: #212121;
        box-shadow: inset -5px 5px 10px #191919,
                     inset 5px -5px 10px #292929;
       }
       
       .span {
        opacity: 0.5;
        transition: opacity 0.2s ease;
       }
       
       .button:hover span {
        opacity: 0.7;
       }



       .pag{
        display: flex;
        justify-content: center;
        margin: 10px;       }
       .buttonPrevNext{
       background-color: #181818;
       color: white;
       }
       .opciones{
        display: flex;
        flex-direction: row;
        justify-content: center;
        height: 3rem;
        flex-wrap: nowrap;
        align-items: center;
        /* border-left: 1px;
        border-right: 1px; */
        /* border: 1px; */
        border:none
    }
 

