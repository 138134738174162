*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
}
  
  
 
.background{
  background-image: url("https://res.cloudinary.com/drjppu0py/image/upload/v1663120507/nasa-Q1p7bh3SHj8-unsplash_lkdni3.jpg"); 
 min-height: 100%;
 width: 100%;
 height: auto;
 background-size:cover;
 background-position:right;
 background-repeat: no-repeat;
 position:absolute;
 top: 10;
 left: 0;
 /* display: flex; */
 /* align-items: flex-start; */
 flex-direction: column;
 justify-content: space-between;
}
.titulo{  
 font-size: 170px;
 color:rgb(255, 255, 255);
 font-weight: 450;
 display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
padding: 10.9589px 19.7967px;
gap: 5.66px;
position: absolute;
width: 450px;
height: 20.59px;
left: 550px;
top: 200.33px;
text-shadow: 2px 4px black;

 } 

    .button {
      align-items: center;
      display: flex;
      justify-content: center;
      margin: 50px;
      border: none;
      padding: 0.7em 2em;
      font-size: 18px;
      position: absolute;
      width: 180px;
      height: 39.59px;
      left:650px;
      top: 292.33px;
  
      /* align-items: center;
      justify-content: center; */
     }
     
     /* .button:hover {
      background: black;
      transition: background 1s ease;
      box-shadow: -5px 5px 10px #191919,
                  5px -5px 10px #292929;
     }
     
    .button:active {
      transition: box-shadow .1s ease;
      border-radius: 9px;
      background: #212121;
      box-shadow: inset -5px 5px 10px #191919,
                   inset 5px -5px 10px #292929;
     }
     
     span {
      opacity: 0.5;
      transition: opacity 0.2s ease;
     }
     
     .button:hover span {
      opacity: 0.7;
     } */

