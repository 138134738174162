.background{
   /* background-image: url("C:\Users\agust\OneDrive\Escritorio\pi\client\src\components\img\abstract-smooth-empty-grey-studio-well-use-as-background-business-report-digital-website-template-backdrop.jpg"); */
   height: 100% ;
   width: 100% ;
   background-size:cover;
   background-position:right;
   background-repeat: no-repeat;
   position:absolute;
   top: 10;
   left: 0;
   flex-direction: column;
   justify-content: space-between;
}
.imagenLoading{
    width: 8rem;
    height: 8rem;

}

.loading{
    width: 100%;
    height: 80vh;
    display: flex;
    animation: animar 5s cubic-bezier(0, 0, 1, 1.03) infinite;
    justify-content: center;
    align-items: center;
}

@keyframes animar {
    0% {
      transform: rotate(0deg);

    }

    100% {
      transform: rotate(360deg);

    }
}  





.contenedor{
    display: flex;
    padding: 1rem;
    margin: 1rem;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    /* background-color: #bebebe; */
}
.imagen{
    width: auto;
    height: 15rem;
    padding: 1rem;
}
.detalles{
    padding: 1rem;
    width: 300px;
    height: 300px;
    border-radius: 20px;
    background: #e0e0e0;
    box-shadow: 10px 10px 20px #bebebe,
                -10px -10px 20px #ffffff;
}
.boxActivity{
    padding: 1rem;
    width: 300px;
    height: 240px;
    border-radius: 20px;
    background: #e0e0e0;
    box-shadow: 10px 10px 20px #bebebe,
                -10px -10px 20px #ffffff;
}
.activity{
    color: rgb(73, 73, 73);
    display: flex;
    justify-content: space-evenly;
    flex-wrap: nowrap;
    flex-direction: row;
    border: 1rem;
    height: 15rem;
    /* background-color: rgba(76, 0, 130, 0.099); */
    /* width: 10rem; */
    /* background-color: #bebebe; */
}

.titulo{
color:#6e6e6e;
}
.Button {
  margin: 1rem;
   
   }
   
  .button:hover {
    background: #212121;
    transition: background 1s ease;
    box-shadow: -5px 5px 10px #191919,
                5px -5px 10px #292929;
   }
   
   .button:active {
    transition: box-shadow .1s ease;
    border-radius: 9px;
    background: #212121;
    box-shadow: inset -5px 5px 10px #191919,
                 inset 5px -5px 10px #292929;
   }
   
   .span {
    opacity: 0.5;
    transition: opacity 0.2s ease;
   }
   
   .button:hover span {
    opacity: 0.7;
   }
