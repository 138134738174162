@import url(https://fonts.googleapis.com/css?family=Raleway);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
}
  
  
 
.LandingPage_background__3GF3U{
  background-image: url("https://res.cloudinary.com/drjppu0py/image/upload/v1663120507/nasa-Q1p7bh3SHj8-unsplash_lkdni3.jpg"); 
 min-height: 100%;
 width: 100%;
 height: auto;
 background-size:cover;
 background-position:right;
 background-repeat: no-repeat;
 position:absolute;
 top: 10;
 left: 0;
 /* display: flex; */
 /* align-items: flex-start; */
 flex-direction: column;
 justify-content: space-between;
}
.LandingPage_titulo__16tlX{  
 font-size: 170px;
 color:rgb(255, 255, 255);
 font-weight: 450;
 display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
padding: 10.9589px 19.7967px;
grid-gap: 5.66px;
gap: 5.66px;
position: absolute;
width: 450px;
height: 20.59px;
left: 550px;
top: 200.33px;
text-shadow: 2px 4px black;

 } 

    .LandingPage_button__3SFnT {
      align-items: center;
      display: flex;
      justify-content: center;
      margin: 50px;
      border: none;
      padding: 0.7em 2em;
      font-size: 18px;
      position: absolute;
      width: 180px;
      height: 39.59px;
      left:650px;
      top: 292.33px;
  
      /* align-items: center;
      justify-content: center; */
     }
     
     /* .button:hover {
      background: black;
      transition: background 1s ease;
      box-shadow: -5px 5px 10px #191919,
                  5px -5px 10px #292929;
     }
     
    .button:active {
      transition: box-shadow .1s ease;
      border-radius: 9px;
      background: #212121;
      box-shadow: inset -5px 5px 10px #191919,
                   inset 5px -5px 10px #292929;
     }
     
     span {
      opacity: 0.5;
      transition: opacity 0.2s ease;
     }
     
     .button:hover span {
      opacity: 0.7;
     } */


.Card_card__3F8np{
    width: 190px;
    height: 254px;
    border-radius: 30px;
    background: #e0e0e0;
    box-shadow: 15px 15px 30px #bebebe,
                -15px -15px 30px #ffffff;
                
                
}

.Card_borde__1_cv-{
    width: 15rem;
    height: 18rem;
    border-radius: 1rem;
    margin: 1rem;
    /* border: solid 1px rgb(64 9 86); */
    background: #e0e0e0;
    padding: 0.5rem;
    display: inline-grid;
    align-content: space-around;
    /* box-shadow: 15px 15px 50px #bebebe,
             -15px -15px 50px #ffffff; */
}

.Card_imagen__9KJdf{
    background: center;
    width: 8rem;
    height: 5rem;
}

.Card_contenido__2bui6{
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    flex-direction: column;
    align-content: space-around;
    padding: none;
    color: rgba(0, 0, 0, 0.76);
    text-decoration: none;
}

.Card_pais__1IZiz{
    height: 4rem;
    width: 12rem;
    text-align: center;
    
}

.Card_detalle__2U_Q-{
    height: 40px;
    width: auto;
    
}

.Card_button__1P8HC{
    font-family: inherit;
    margin:1rem ;
    pointer-events: all;
    border-radius: 1rem;
    background: none;
    border: none;
    font-size: 11px;
    padding: 5px;
    cursor: pointer;
    box-shadow: 1px  1px 1px 1px rgb(65, 65, 65);
    color:black;
    
}


.Paginado_button__2ZmTj{
    /* margin: 3px;
           border: none;
           /* padding: 6px; */
           font-size: 13px;
           font-family: inherit;
           background-blend-mode: multiply;
           color: rgb(235, 234, 234);
           border-radius: 9px;
           background: linear-gradient(225deg, #181818, #2e2e2e); 
           /* box-shadow: -5px 5px 10px #191919,
                       5px -5px 10px #292929; */
          }
          
         .Paginado_button__2ZmTj:hover {
           background: #212121;
           transition: background 1s ease;
           box-shadow: -5px 5px 10px #191919,
                       5px -5px 10px #292929;
          }
          
          .Paginado_button__2ZmTj:active {
           transition: box-shadow .1s ease;
           border-radius: 9px;
           background: #212121;
           box-shadow: inset -5px 5px 10px #191919,
                        inset 5px -5px 10px #292929;
          }
          
          .Paginado_span__3NiNG {
           opacity: 0.5;
           transition: opacity 0.2s ease;
          }
          
          .Paginado_button__2ZmTj:hover span {
           opacity: 0.7;
          }

.Paginado_buttonPrevNext__2Q39N{
border: none;
           padding: 5px;
           font-size: 13px;
           font-family: inherit;
           background-blend-mode: multiply;
           color: rgb(235, 234, 234);
           border-radius: 9px;
           background: linear-gradient(225deg, #181818, #2e2e2e);
           
          }
          
         .Paginado_button__2ZmTj:hover {
           background: #212121;
           transition: background 1s ease;
           box-shadow: -5px 5px 10px #191919,
                       5px -5px 10px #292929;
          }
          
          .Paginado_button__2ZmTj:active {
           transition: box-shadow .1s ease;
           border-radius: 9px;
           background: #212121;
           box-shadow: inset -5px 5px 10px #191919,
                        inset 5px -5px 10px #292929;
          }
          
          .Paginado_span__3NiNG {
           opacity: 0.5;
           transition: opacity 0.2s ease;
          }
          
          .Paginado_button__2ZmTj:hover span {
           opacity: 0.7;
          }

.SearchBar_input__3Mb6o {
    border: none;
    /* padding: 1rem; */
    margin: 10px;
    border-radius: 1vh;
    background: #e8e8e8;
    /* box-shadow: 5px 5px 50px #c5c5c5,
           -20px -20px 60px #ffffff; */
    transition: 0.3s;
   }
   
   .SearchBar_input__3Mb6o:focus {
    outline-color: #e8e8e8;
    background: #e8e8e8;
    box-shadow: inset 20px 20px 60px #c5c5c5,
           inset -20px -20px 60px #ffffff;
    transition: 0.3s;
   }
   
   /* .button{
       margin: 3px;
              border: none;
              padding: 0.7em 2em;
              font-size: 13px;
              font-family: inherit;
              background-blend-mode: multiply;
              color: rgb(235, 234, 234);
              border-radius: 9px;
              background: linear-gradient(225deg, #181818, #2e2e2e);
              /* box-shadow: -5px 5px 10px #191919,
                          5px -5px 10px #292929; */
             /* } */
             
            /* .button:hover {
              background: #212121;
              transition: background 1s ease;
              box-shadow: -5px 5px 10px #191919,
                          5px -5px 10px #292929;
             }
             
             .button:active {
              transition: box-shadow .1s ease;
              border-radius: 9px;
              background: #212121;
              box-shadow: inset -5px 5px 10px #191919,
                           inset 5px -5px 10px #292929;
             }
             
             .span {
              opacity: 0.5;
              transition: opacity 0.2s ease;
             }
             
             .button:hover span {
              opacity: 0.7;
             }
       */ 
   
   .SearchBar_barra__3qWHd{
       margin: 20px;
   }
.Detail_background__3lRrp{
   /* background-image: url("C:\Users\agust\OneDrive\Escritorio\pi\client\src\components\img\abstract-smooth-empty-grey-studio-well-use-as-background-business-report-digital-website-template-backdrop.jpg"); */
   height: 100% ;
   width: 100% ;
   background-size:cover;
   background-position:right;
   background-repeat: no-repeat;
   position:absolute;
   top: 10;
   left: 0;
   flex-direction: column;
   justify-content: space-between;
}
.Detail_imagenLoading__2eBic{
    width: 8rem;
    height: 8rem;

}

.Detail_loading__2G2fU{
    width: 100%;
    height: 80vh;
    display: flex;
    -webkit-animation: Detail_animar__2PCqd 5s cubic-bezier(0, 0, 1, 1.03) infinite;
            animation: Detail_animar__2PCqd 5s cubic-bezier(0, 0, 1, 1.03) infinite;
    justify-content: center;
    align-items: center;
}

@-webkit-keyframes Detail_animar__2PCqd {
    0% {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);

    }

    100% {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg);

    }
}

@keyframes Detail_animar__2PCqd {
    0% {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);

    }

    100% {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg);

    }
}  





.Detail_contenedor__nH7WL{
    display: flex;
    padding: 1rem;
    margin: 1rem;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    /* background-color: #bebebe; */
}
.Detail_imagen__b80ZI{
    width: auto;
    height: 15rem;
    padding: 1rem;
}
.Detail_detalles__8pX-x{
    padding: 1rem;
    width: 300px;
    height: 300px;
    border-radius: 20px;
    background: #e0e0e0;
    box-shadow: 10px 10px 20px #bebebe,
                -10px -10px 20px #ffffff;
}
.Detail_boxActivity__239OQ{
    padding: 1rem;
    width: 300px;
    height: 240px;
    border-radius: 20px;
    background: #e0e0e0;
    box-shadow: 10px 10px 20px #bebebe,
                -10px -10px 20px #ffffff;
}
.Detail_activity__1lzVW{
    color: rgb(73, 73, 73);
    display: flex;
    justify-content: space-evenly;
    flex-wrap: nowrap;
    flex-direction: row;
    border: 1rem;
    height: 15rem;
    /* background-color: rgba(76, 0, 130, 0.099); */
    /* width: 10rem; */
    /* background-color: #bebebe; */
}

.Detail_titulo__3kpmv{
color:#6e6e6e;
}
.Detail_Button__2NR5t {
  margin: 1rem;
   
   }
   
  .Detail_button__2971z:hover {
    background: #212121;
    transition: background 1s ease;
    box-shadow: -5px 5px 10px #191919,
                5px -5px 10px #292929;
   }
   
   .Detail_button__2971z:active {
    transition: box-shadow .1s ease;
    border-radius: 9px;
    background: #212121;
    box-shadow: inset -5px 5px 10px #191919,
                 inset 5px -5px 10px #292929;
   }
   
   .Detail_span__1teTQ {
    opacity: 0.5;
    transition: opacity 0.2s ease;
   }
   
   .Detail_button__2971z:hover span {
    opacity: 0.7;
   }

body {
    margin: 0 px;
  }
  nav {
    /* margin-top: 40px; */
    /* height: 20%; */
    /* height: 5rem; */
    padding: 24px;
    text-align: end;
    font-family: Roboto;
    /* box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.5); */
    background-color: #bebebe;
    display: block;
  }
 .Home_nav__2l528 {
    background: #bebebe;
    height: 72px; 
   display: flex;
   justify-content: space-between;
   
    
  }
  

  .Home_link__29sQG:hover {
    border-top: 4px solid #ffffff;
    border-bottom: 4px solid #ffffff;
    padding: 6px 0; 
    /* margin: 0%; */
  }
  .Home_link__29sQG{
    transition: 0.3s ease;
  background: #181818;
  color: #ffffff;
  font-size: 20px;
  text-decoration: none;
  border-top: 4px solid #9256ED;
  border-bottom: 4px solid #9256ED;
  padding: 20px 0;
  margin: 0 20px;
  }


.Home_cardContent__16Mrr{
    display: flex;
    /* margin: auto; */
    padding: 2rem;
    font: times;
    width: auto;
    height: auto;
    /* background-image: url(../Landing/landing.jpg); */
    flex-direction: row;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
    align-items: center;
    /* background-color: rgba(253, 253, 253, 0.758); */

} 

.Home_orden__2Wxr3{
    margin: 1rem;
    border: none;
    padding: 0.7em 2em;
    font-size: 13px;
    font-family: inherit;
    background-blend-mode: multiply;
    color: rgb(235, 234, 234);
    border-radius: 9px;
    background: linear-gradient(225deg, #181818, #2e2e2e);
    /* box-shadow: -5px 5px 10px #191919,
                5px -5px 10px #292929; */
                /* justify-content: space-evenly; */
   }
   
  .Home_orden__2Wxr3:hover {
    background: #212121;
    transition: background 1s ease;
    box-shadow: -5px 5px 10px #191919,
                5px -5px 10px #292929;
   }
   
   .Home_orden__2Wxr3:active {
    transition: box-shadow .1s ease;
    border-radius: 9px;
    background: #212121;
    box-shadow: inset -5px 5px 10px #191919,
                 inset 5px -5px 10px #292929;
   }
   
   .Home_span__1hr1h {
    opacity: 0.5;
    transition: opacity 0.2s ease;
   }
   
   .Home_orden__2Wxr3:hover span {
    opacity: 0.7;
   }

   .Home_logo__bbiir {
    display: flex;
    width: 200px;
    margin: 0 auto;
  }
.Home_background__1SYj-{
    /* background-image: url("C:\Users\agust\OneDrive\Escritorio\pi\client\src\components\img\abstract-smooth-empty-grey-studio-well-use-as-background-business-report-digital-website-template-backdrop.jpg");  */
   min-height:100%;
   min-width: 100%;
    height: auto;
    background-size:cover;
    background-position:center;
    background-repeat: no-repeat;
     position:absolute;
    /* top: 10;
    left: 0;
    display: flex;  
    align-items: flex-start;   
    flex-direction:row;
   justify-content: space-between; */
    background-color: #bebebe 
}



    .Home_button__k4kBI {
        border: none;
        padding: 0.7em 2em;
        font-size: 15px;
        font-family: inherit;
        background-blend-mode: multiply;
        color: rgb(235, 234, 234);
        border-radius: 9px;
        background: linear-gradient(225deg, #181818, #2e2e2e);
        /* box-shadow: -5px 5px 10px #191919,
                    5px -5px 10px #292929; */
       }
       
      .Home_button__k4kBI:hover {
        background: #212121;
        transition: background 1s ease;
        box-shadow: -5px 5px 10px #191919,
                    5px -5px 10px #292929;
       }
       
       .Home_button__k4kBI:active {
        transition: box-shadow .1s ease;
        border-radius: 9px;
        background: #212121;
        box-shadow: inset -5px 5px 10px #191919,
                     inset 5px -5px 10px #292929;
       }
       
       .Home_span__1hr1h {
        opacity: 0.5;
        transition: opacity 0.2s ease;
       }
       
       .Home_button__k4kBI:hover span {
        opacity: 0.7;
       }



       .Home_pag__2-HF6{
        display: flex;
        justify-content: center;
        margin: 10px;       }
       .Home_buttonPrevNext__3wS29{
       background-color: #181818;
       color: white;
       }
       .Home_opciones__1kQ0q{
        display: flex;
        flex-direction: row;
        justify-content: center;
        height: 3rem;
        flex-wrap: nowrap;
        align-items: center;
        /* border-left: 1px;
        border-right: 1px; */
        /* border: 1px; */
        border:none
    }
 


.ActivityCreate_background__3XaXP{
    /* background-image: url("C:\Users\agust\OneDrive\Escritorio\pi\client\src\components\img\abstract-smooth-empty-grey-studio-well-use-as-background-business-report-digital-website-template-backdrop.jpg"); */
   height: 100% ;
  width: 100% ;
   /* height: auto; */
   background-size:cover;
   background-position:right;
   background-repeat: no-repeat;
   position:absolute;
   top: 10;
   left: 0;
   /* display: flex;  */
    /* align-items: flex-start;  */
   flex-direction: column;
   justify-content: space-between;
}

.ActivityCreate_formulario__1MXZ0{
   width:300px;
   margin: 0 auto;
}
select{
    /* width: 100%; */
    padding: 16px 20px;
    border: none;
    border-radius: 4px;
    background-color: #f1f1f1;
  }
  input {
     width: 15%;
    padding: 9px 20px;
    margin: 8px 0;
    box-sizing: border-box;
  }
  .ActivityCreate_button__2wRpV {
    margin: 1rem;
    border: none;
    /* padding: 0.7em 2em; */
    padding: 0.7em 2em;
    font-size: 18px;
    font-family: inherit;
    background-blend-mode: multiply;
    color: rgb(235, 234, 234);
    border-radius: 9px;
    background: linear-gradient(225deg, #181818, #2e2e2e);
    /* box-shadow: -5px 5px 10px #191919,
                5px -5px 10px #292929; */
   }
   
  .ActivityCreate_button__2wRpV:hover {
    background: #212121;
    transition: background 1s ease;
    box-shadow: -5px 5px 10px #191919,
                5px -5px 10px #292929;
   }
   
   .ActivityCreate_button__2wRpV:active {
    transition: box-shadow .1s ease;
    border-radius: 9px;
    background: #212121;
    box-shadow: inset -5px 5px 10px #191919,
                 inset 5px -5px 10px #292929;
   }
   
   .ActivityCreate_span__3iWTt {
    opacity: 0.5;
    transition: opacity 0.2s ease;
   }
   
   .ActivityCreate_button__2wRpV:hover span {
    opacity: 0.7;
   }

 .ActivityCreate_temp__WIypj{
    padding: 1rem;
} 
.ActivityCreate_inputTemp__2GaCU{
    width: 40px;
}
.Error_background__GoCW_{
    /* background-image: url("C:\Users\agust\OneDrive\Escritorio\pi\client\src\components\img\eror-404.jpg"); */
    min-height:100%;
    min-width: 100%;
     height: auto;
     background-size:cover;
     background-position:right;
     background-repeat: no-repeat;
     position:absolute;
     top: 10;
     left: 0;
     /* display: flex;  
     align-items: flex-start;   */
     flex-direction: column;
     justify-content: space-between;
}
